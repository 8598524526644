@import "assets/styles/css/bootstrap.css";
//@import "assets/styles/css/datepicker3.css";
@import "assets/styles/css/styles.css";
@import "assets/styles/css/font-awesome.min.css";
@import '../node_modules/@angular/material/theming';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

body {
    a, input[type='submit'], input[type='image'], select, button, mat-menu .pointer {
        cursor: pointer;
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.no-hover-effect.mat-button.mat-primary .mat-button-focus-overlay .cdk-button-focus-overlay,
.no-hover-effect.mat-button.mat-accent .mat-button-focus-overlay .cdk-button-focus-overlay,
.no-hover-effect.mat-button.mat-warn .mat-button-focus-overlay .cdk-button-focus-overlay {
    background-color: transparent !important;
    outline: none;
}

::ng-deep .default-theme.cdk-focused, .cdk-mouse-focused {
    background-color: transparent !important;
}

.no-hover-effect {
    background-color: transparent !important;
}

* {
    font-family: 'Poppins', sans-serif;
}


// overwrite the ripple overlay on hover and click
::ng-deep .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-indeterminate.mat-checkbox-ripple .mat-ripple-element {
    background-color: ghostwhite !important;
}
::ng-deep mat-option mat-pseudo-checkbox.mat-pseudo-checkbox .mat-primary .mat-pseudo-checkbox-checked,.mat-option-pseudo-checkbox .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #999!important;
}

::ng-deep .default-theme .mat-radio-button.mat-accent .mat-radio-inner-circle, .default-theme .mat-radio-button.mat-accent
.mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.default-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.default-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: transparent !important;
}

::ng-deep.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #214184!important;
}

::ng-deep.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: #214184!important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #214184!important;
}
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background-color: #214184!important;
}


.container {
    margin-top: 25px;
}

.big-icon {
    font-size: 48px;
    color: white;
}

.icon {
    margin-right: 5px;
}

img {
    vertical-align: middle;
    padding: 2px;
}

.img-size{ border: 2px solid #214184;}


.asp-card-margin {
    margin: 3% 0;
    height: auto;
}

.astra-content-padding{
    min-height: 82vh;
    height: auto;
}

.asp-card-title {
    color: #214184 !important;
}

a {
    color: #0a6ebd;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #eeeeee;
    font-size: 12px;
}

.card {
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: #333333;
    background: #fff;
    width: 100%;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card .card-category:not([class*="text-"]) {
    color: #999999;
    line-height: 24px;
}

.card [class*="card-header-"] {
    margin: 0px 15px 0;
    padding: 0;
    position: relative;
}

.card [class*="card-header-"] .card-icon,
.card [class*="card-header-"] .card-text {
    border-radius: 3px;
    padding: 15px;
    margin-top: -50px;
    margin-right: 15px;
    float: left;
}

.card [class*="card-header-"] .card-text {
    float: none;
    display: inline-block;
    margin-right: 0;
}

.card [class*="card-header-"] .card-text .card-title {
    color: #fff;
    margin-top: 0;
}

.card [class*="card-header-"] .card-title + .card-category {
    color: rgba(255, 255, 255, 0.62);
}

.card [class*="card-header-"] .card-title + .card-category a {
    color: #fff;
}

.card.card-stats .card-header .card-icon + .card-title,
.card.card-stats .card-header .card-icon + .card-category {
    padding-top: 10vh;
}

@media(max-width: 380px) {
    .card.card-stats .card-header .card-icon + .card-title,
    .card.card-stats .card-header .card-icon + .card-category {
        padding-top: 37vh;
    }

    .asp-card-margin {
        margin-right: 3% !important;
        margin-left: 3% !important;
        margin-top: 5vh !important;
    }
}

@media only screen and (max-width: 768px) {
    .card.card-stats .card-header .card-icon + .card-title,
    .card.card-stats .card-header .card-icon + .card-category {
        padding-top: 17vh;
    }

    .asp-card-margin {
        margin-right: 3% !important;
        margin-left: 3% !important;
        margin-top: 5vh !important;
    }
}

@media only screen and (max-width: 1024px) {
    .asp-card-margin {
        margin-right: 3% !important;
        margin-left: 3% !important;
        margin-top: 5vh !important;
    }
}

.card.card-stats .card-header .card-title,
.card.card-stats .card-header .card-category {
    margin: 0;
}

.card.card-stats .card-header .card-category {
    margin-bottom: 0;
    margin-top: 0;
}

.card.card-stats .card-header .card-category:not([class*="text-"]) {
    color: #999999;
    font-size: 14px;
    line-height: 24px;
}

.card.card-stats .card-header + .card-footer {
    border-top: 1px solid #eee;
    margin-top: 14px;
}

.card.card-stats .card-header i {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center;
}

.margin-15 {
    margin: 15px;
}

.img-size {
    width: 400px !important;
    background-color: white;
}

@media (max-width: 768px) {
    .img-size {
        width: 250px !important;
        background-color: white;
    }
}
